import * as amplitude from '@amplitude/analytics-browser';
import { IAmplitude } from './abstractions';

export class Amplitude implements IAmplitude {
  apiKey: string;
  isInitialized: boolean = false;

  constructor(apiKey: string = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ?? '') {
    this.apiKey = apiKey;
    this.initialize();
  }

  private isServerEnv(): boolean {
    return typeof window === 'undefined';
  }

  private initialize(): void {
    if (this.isServerEnv()) {
      return;
    }

    if (!this.isInitialized) {
      amplitude.init(this.apiKey, {
        defaultTracking: {
          sessions: false,
          pageViews: false,
          formInteractions: false,
          fileDownloads: false,
        },
      });
      this.isInitialized = true;
    }
  }

  setUser(id: string): void {
    if (this.isServerEnv()) {
      return;
    }

    this.initialize();
    amplitude.setUserId(id);
  }

  trackEvent(name: string, data?: Record<string, unknown>): void {
    if (this.isServerEnv()) {
      return;
    }

    this.initialize();
    amplitude.track(name, data);
  }
}
